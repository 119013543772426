import '../App.css';
import React from 'react';
import { GoogleOAuthProvider, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import one from '../assets/logoHigh.png'
import two from '../assets/center.webp'
import google from '../assets/google.png'
import bg from '../assets/authBG.webp'
import privecy from '../assets/privacy.svg'
import terms from '../assets/documents.svg'

const CLIENT_ID = '572244835192-37epuu87btr11cb9irhimn821nshla31.apps.googleusercontent.com'
const scopes = [
  'https://www.googleapis.com/auth/youtube.readonly',
];


const setWindowLocalStroage = (item, key) => {
  window.localStorage.setItem(key, item)
}

const GoogleSignIn = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);

  const showAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const accessToken = response.access_token; // OAuth Access Token

      // Fetch YouTube channel data using the access token
      await fetchYouTubeChannelID(accessToken);
    },
    onError: (error) => {
      console.error('Google Sign-In failed', error);
    },
    scope: scopes
  });

  const fetchYouTubeChannelID = async (accessToken) => {
    try {
      const youtubeResponse = await axios.get('https://www.googleapis.com/youtube/v3/channels', {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Use the OAuth Bearer Token
        },
        params: {
          part: 'snippet',
          mine: true, // To fetch the authenticated user's channel
        },
      });
      console.log(youtubeResponse.data);


      const youtubeChannelID = youtubeResponse.data.items[0].id; // YouTube Channel ID
      await setWindowLocalStroage(youtubeChannelID, 'id')
      await setWindowLocalStroage(youtubeResponse?.data?.items[0]?.snippet?.title, 'title')
      await setWindowLocalStroage(youtubeResponse?.data?.items[0]?.snippet?.thumbnails?.medium?.url, 'profile')
      await setWindowLocalStroage(accessToken, 'token')
      window.location.reload()

    } catch (error) {
      // alert('Google Sign Retriving Youtube ID Failed')
      setAlertOpen(true)
    }
  };


  return (
    <>
      {!alertOpen &&
        <div class="flex items-center justify-center ">
          <button onClick={() => login()}
            className="bg-gradient-to-r from-[#1E41B1] flex flex-row justify-between items-center to-[#2562E9] text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:from-blue-600 hover:to-blue-800 transition duration-300 ease-in-out"
          >
            <img src={google} className='w-5 h-5 mr-5' />
            <h1 className='-pt-2 text-sm'>Continue with Google</h1>

          </button>
        </div>}
      {alertOpen && 
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center text-center">
          <p className='text-white text-xs mx-52 uppercase'>Missing Youtube ID. To continue please make sure the required permissions are granted</p>
          <button className='bg-gradient-to-r rounded-md from-[#1E41B1] mx-[25%] py-2 mt-5 text-sm justify-between to-[#2562E9] text-white' onClick={() => login()}>Allow Access For Youtube ID</button>
        </div>
      </div>}
    </>

  );
}


function index() {
  return (
    <div className="w-[100vw] h-[100vh] flex flex-row justify-between items-center">
      <div className='flex flex-col justify-center items-center w-[100%] md:w-[70%] h-[100%] bg-[black] z-10 shadow-lg shadow-black ' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className='flex flex-col justify-center items-center'>
          <img src={one} className='w-[70%] ' />
        </div>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <GoogleSignIn />
        </GoogleOAuthProvider>

        <div className='flex  mt-36 flex-row justify-around w-[100%] items-center cursor-pointer text-white lowercase text-ce underline-offset-8 text-xs '>
          <a href='/privacy-policy' className='flex flex-row underline'><img src={privecy} className='w-5 h-5 mr-2 text-white' />Privacy Policy</a>
          <a href='/terms-and-conditions' className='flex flex-row underline'><img src={terms} className='w-5 h-5 mr-2 text-white' />Terms and Conditions</a>
        </div>
      </div>
      <div className='w-[0px] md:w-[100%]   h-[100vh] blur-sm bg-[#481455be] ' >
        <div className='bg-[red]-z-2'>
          <div className='w-[100vw] h-[100vh] fixed bg-gradient-to-r from-[#100036] via-[#630772] to-[#110137] m-0 p-0 top-0 left-0'></div>
          <img src={one} className='fixed w-[100%] h-[100%] right-[-250px] top-[-40%] blur-md' />
          <img src={two} className='fixed w-[100%] h-[100%] left-[-50%] top-[50px] rotate-[0deg]' />
          <img src={two} className='fixed w-[100%] h-[100%] right-[-30%] bottom-[-50%] ' />
        </div>
      </div>
    </div>
  );
}

export default index;
