import React from 'react';

export default function TermsOfService() {
    return (
        <iframe
            src="/term.html"
            title="HTML Content"
            className='w-[100%] h-[100vh] border-none px-10'

        />
    );
}
