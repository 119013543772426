import React, { useEffect } from 'react';
import GoogleSignIn from '../GoogleSignIn';
import bg from '../assets/authBG.webp'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import one from '../assets/logoHigh.png'
import two from '../assets/center.webp'
import privecy from '../assets/privacy.svg'
import terms from '../assets/documents.svg'



function Consumer() {
  const [id, setID] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [profile, setProfile] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [data, setData] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [transactionDetail, setTransactionDetail] = React.useState({

  })
  const getifYourAWinner = async () => {
    try {
      let headersList = {
        "Content-Type": "application/json"
      }
      let bodyContent = JSON.stringify({
        "userId": id,
      });

      let response = await fetch(`${process.env.REACT_APP_FUNC_URL}getWinningAmmount`, {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });
      let data = await response.text();
      let res = JSON.parse(data);
      console.log(res)
      setData(res)
      if (res?.phone) {
        setPhone(res?.phone)
      }

    } catch (e) {
      console.log(e)
    }
  }

  const savePhone = async () => {
    try {
      let headersList = {
        "Content-Type": "application/json"
      }
      let bodyContent = JSON.stringify({
        "userId": id,
        "name": title,
        "phone": phone
      });

      let response = await fetch(`${process.env.REACT_APP_FUNC_URL}getphone`, {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });
      let data = await response.text();
      console.log(data)
    } catch (e) {
      console.log(e)
    }
  }
  const getLocalStorageId = (key, setData) => {
    let data = window.localStorage.getItem(key);
    setData(data);

  }

  const logout = async () => {
    let accessToken = await window.localStorage.getItem('token');
    fetch(`https://oauth2.googleapis.com/revoke?token=${accessToken}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      }
    })
      .then(() => {
        console.log('Access revoked');
        window.localStorage.removeItem('id')
        window.location.reload()
      })
      .catch((error) => {
        console.error('Error revoking access:', error);
      });

  }

  const forgetID = () => {
    window.localStorage.removeItem('id')
    window.location.reload()
  }



  useEffect(() => {
    getLocalStorageId('id', setID)
    getLocalStorageId('title', setTitle)
    getLocalStorageId('profile', setProfile)
    // fetchBanks()
  }, [])
  useEffect(() => {
    if (id) {
      getifYourAWinner()
    }

  }, [id])

  return (
    <div className='overflow-hidden'>
      {id ?
        <div className="w-[100vw] h-[100vh] flex flex-row justify-between items-center">
          <div className='flex flex-col justify-center items-center w-[100%] md:w-[70%] h-[100%] pb-10 bg-[black] z-10 shadow-lg shadow-black' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <img src={one} className='w-[50%] ' />
            <div className='w-[100%] px-10'>
              <div className='w-[100%] flex flex-row items-center'>
                <img src={profile} className='w-20 h-20 mb-2 border-[10px] rounded-2xl' />
                <h1 className='ml-5 text-white text-xl font-bold' >Welcome <span className='text-emerald-600 uppercase font-bold' >{title}</span></h1>
              </div>
              {data?.amount ? <div className='py-2 mt-5 text-2xl text-[#8915A6] bg-white font-bold px-5 rounded-md shadow-[black] mb-2'>
                You have Won<span className='text-emerald-500 font-bold'> {data.amount}{" "}Birr</span>
              </div> : <div className='py-2 mt-5 text-2xl text-[#8915A6] bg-white font-bold px-5 rounded-md shadow-[black] mb-2'>
                You have no prizes<span className='text-emerald-500 font-bold'></span>
              </div>}
              {data?.amount ?
                <div className='flex flex-col  w-[100%]'>
                  <h1 className='text-white text-lg pt-5 mb-5 uppercase '>Please Send your Telebir Wallet Phone Number</h1>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    countries={['ET']}
                    defaultCountry='ET'
                    className="bg-[#fcfcfc] border-[1px] border-[#3B3B3B] text-[black] px-2 py-2 rounded-md "
                    onChange={setPhone} />

                  {data?.status ? <a onClick={() => {
                    if (!phone || !id) {
                      alert("Missing Credentials");
                    } else {
                      // savePhone()
                      alert("Information already submited")
                    }
                  }} className="text-xl uppercase mt-5 cursor-pointer hover:border-[#b345f3] underline underline-offset-4 text-white rounded-md">
                    Thank You
                  </a> : <a onClick={() => {
                    if (!phone || !id) {
                      alert("Missing Credentials");
                    } else {
                      savePhone()
                    }
                  }} className="text-xl text-white uppercase mt-5 cursor-pointer hover:border-[#b345f3]  underline underline-offset-4 rounded-md">
                    Submit
                  </a>}
                </div>
                : null}


              <div className='absolute left-10 top-10'>
                <button onClick={toggleDropdown} onMouseEnter={toggleDropdown}
                  data-dropdown-toggle="dropdown" className="text-white bg-[#0000007a] px-2 py-2 rounded-md" type="button">Settings
                </button>
                {isOpen && (
                  <div id="dropdown" className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <a onClick={() => forgetID()} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Log Out</a>
                      </li>
                      <li>
                        <a onClick={() => logout()} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete Account</a>
                      </li>
                      <li className='mt-2 ml-3 border-t-[1px] pt-3'>
                        <a href='/privacy-policy' className='flex flex-row underline text-xs'><img src={privecy} className='w-5 h-5 mr-2 text-white' />Privacy Policy</a>
                      </li><li className='mt-3 ml-2'>
                        <a href='/terms-and-conditions' className='flex flex-row underline text-xs'><img src={terms} className='w-5 h-5 mr-2 text-white' />Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              {/* <button className='absolute right-[62%] bottom-2 uppercase underline underline-offset-4  text-white font-bold py-2 px-4 rounded-lg shadow-lg  transition duration-300 ease-in-out' onClick={() => forgetID()}>log out</button> */}
            </div>
          </div>
          <div className='w-[0px] md:w-[100%]   h-[100vh] blur-sm bg-[#481455be] ' >
            <div className='bg-[red]-z-2'>
              <div className='w-[100vw] h-[100vh] fixed bg-gradient-to-r from-[#100036] via-[#630772] to-[#110137] m-0 p-0 top-0 left-0'></div>
              <img src={one} className='fixed w-[100%] h-[100%] right-[-250px] top-[-40%] blur-md' />
              <img src={two} className='fixed w-[100%] h-[100%] left-[-50%] top-[50px] rotate-[0deg]' />
              <img src={two} className='fixed w-[100%] h-[100%] right-[-30%] bottom-[-50%] ' />
            </div>
          </div>
        </div>
        :
        <GoogleSignIn />
      }
    </div>
  );
}

export default Consumer;




{/* <div className="flex flex-col justify-center item-center mt-5">
              <div className="max-w-sm mx-auto mb-5">
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a bank</label>
                <select name='bank_code' onChange={e => selectBank(e)} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {banks.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>

                  ))}
                </select>
              </div>
              <input name='holder_name' onChange={e => onInputChange(e)} className="px-14 rounded-sm text-sm py-2 text-black" placeholder="Account Holder Name" />
              <br />
              <input type='tele' name='account_number' onChange={e => onInputChange(e)} className="px-14 rounded-sm text-sm py-2 text-black" placeholder="Account Number" />
            </div> */}
{/* <a onClick={() => sumbitBankInfo()} className="text-xl uppercase mt-5 cursor-pointer hover:border-2 px-5 rounded-md">
              Claim
            </a> */}