import React from 'react';

export default function Privacy() {

    return (
        <iframe
            src="/privecy.html"
            title="HTML Content"
            className='w-[100%] h-[100vh] border-none px-10'
            
        />
    );
}
