import React, { useEffect, useState } from 'react'
import { Button, Space, Table, Tag } from 'antd';
import one from '../assets/logoHigh.png'
import two from '../assets/center.webp'
import bg from '../assets/authBG.webp'




export default function Admin() {

    const [jsonData, setJsonData] = useState(null);
    const [winers, setWinners] = useState([]);
    const [banks, setBank] = React.useState([])
    const [code, setCode] = React.useState('855')
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(true)

    // Function to handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file && file.type === "application/json") {
            const reader = new FileReader();

            // Once the file is read, parse the JSON and store it in state
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    setJsonData(json);  // Store the parsed JSON data in state
                    console.log("Parsed JSON:", json);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    alert("Invalid JSON file");
                }
            };

            reader.readAsText(file);  // Read the file as a text
        } else {
            alert("Please upload a valid JSON file");
        }
    };

    const getwinners = async () => {
        try {
            let idToken = await window.localStorage.getItem('token');

            let headersList = {
                "Accept": "*/*",
                'Authorization': `Bearer ${idToken}`
            }

            let response = await fetch(`${process.env.REACT_APP_FUNC_URL}getAllDocuments`, {
                method: "GET",
                headers: headersList
            });

            let data = await response.text();
            let res = await JSON.parse(data);
            console.log(res)
            if (res?.message) {
                setError(res.message)
            }
            setLoaded(false)
            setWinners(res)

        } catch (error) {
            console.error('Error', error);
        }
    };
    const fetchBanks = async () => {
        try {
            let headersList = {
                "Accept": "*/*",
            }

            let response = await fetch(`${process.env.REACT_APP_FUNC_URL}getBanks`, {
                method: "GET",
                headers: headersList
            });

            let data = await response.text();
            let jsonData = await JSON.parse(data);
            console.log(jsonData)
            setBank(jsonData.data)
        } catch (e) {
            console.log(e)
        }
    }

    const sumbitBankInfo = async (phone) => {
        let idToken = await window.localStorage.getItem('token');

        try {
            let headersList = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${idToken}`

            }
            let bodyContent = JSON.stringify({
                // "holder_name": holder_name,
                "account_number": phone,
                "bank_code": code ? code : '855',
            });

            let response = await fetch(`${process.env.REACT_APP_FUNC_URL}claimForTransaction/`, {
                method: "POST",
                body: bodyContent,
                headers: headersList
            });

            let data = await response.text();
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    }
    const selectBank = (e) => {
        setBank(e.target.value)
    }


    // Function to send the JSON data to Firebase Cloud Function
    const sendJsonToFirebase = async () => {
        if (!jsonData) {
            alert("Please upload a valid JSON file first");
            return;
        }
        let idToken = await window.localStorage.getItem('token');

        try {
            const response = await fetch(`${process.env.REACT_APP_FUNC_URL}adminSaveWinnersToFirebase`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ jsonFile: jsonData }),
            });

            if (response.ok) {
                const data = await response.json();
                alert('Data sent successfully');
            } else {
                console.error('Error sending data:', response.statusText);
                alert('Failed to send data');
            }
        } catch (error) {
            alert('Error sending data to Firebase');
        }
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Status',
            key: 'phone',
            dataIndex: 'phone',
            render: (item) => {
                if (item) {
                    return <Tag color={'green'} >
                        {item}
                    </Tag>
                } else {
                    return <Tag color='red'>
                        Missing Data To process transaction
                    </Tag>
                }

            }

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (item) => {
                if (item) {
                    return <Tag color={'green'} >
                        {item}
                    </Tag>
                } else {
                    return <Tag color='red'>
                        Not Specified
                    </Tag>
                }

            }
        },
        {
            title: 'Action',
            dataIndex: 'phone',
            key: 'phone',
            render: (item) => {
                if (item) {
                    return (
                        <div className=''>
                            <div className="max-w-sm mx-auto">
                                <label for="countries" className="block  text-sm font-medium text-gray-900 dark:text-white">Select a bank</label>
                                <select defaultValue={855} name='bank_code' onChange={e => selectBank(e)} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    {banks.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>

                                    ))}
                                </select>
                            </div>
                            <Button className='ml-3 mt-3' onClick={() => sumbitBankInfo(item)}>Transfer Now</Button>
                        </div>
                    )
                } else {
                    return <Tag color='red'>
                        Amount Not Specified
                    </Tag>
                }

            }
        },

    ];


    useEffect(() => {
        getwinners()
        fetchBanks()
    }, [])

    if (error) return <div className='flex flex-col justify-center items-center min-h-[100vh] text-xl font-bold text-red-700'><h1 className="text-4xl">403</h1><h1>{error}</h1></div>
    if (loaded) return <div className='flex flex-col justify-center items-center min-h-[100vh] text-xl font-bold text-green-700'><h1>Loading</h1></div>
    return (
        <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className='flex flex-col min-h-[100vh]  bg-[#751cbd] px-10 py-10 overflow-hidden'>
                <div className='flex flex-row justify-between items-center z-10'>
                    <h1 className='text-xl py-10 text-[white] font-bold '>SUBMITTED DATA</h1>
                    <a href='/' className='cursor-pointer z-10'><img src={one} className='w-[120px] ' /></a>

                    <div className="  bg-white rounded-md shadow-xl shadow-black p-2">
                        <h2 className='text-sm font-bold'>Upload JSON File</h2>
                        <input type="file" accept=".json" onChange={handleFileUpload} />
                        <button className='p-3 rounded-lg  text-white font-bold  bg-[#751cbd]' onClick={sendJsonToFirebase} disabled={!jsonData}>
                            Send JSON to Firebase
                        </button>
                    </div>
                </div>
                {winers.length > 0 ?
                    <div className='z-10'>
                        <Table columns={columns} dataSource={winers} pagination={{
                            position: ['top'],
                            pageSize: 8
                        }} />
                    </div> : null}



                <div className='w-[100vw] h-[100vh] fixed bg-gradient-to-r from-[#100036] via-[#630772] to-[#110137] m-0 p-0 top-0 left-0'></div>
                <img src={two} className='fixed w-[60%] h-[100%] left-[-200px] top-[-250px] rotate-[0deg]' />
                <img src={two} className='fixed w-[60%] h-[100%] left-[-300px] bottom-[-350px] rotate-[0deg]' />

                <img src={two} className='fixed w-[60%] h-[100%] right-[-300px] bottom-[-400px] rotate-[10deg]' />
                <img src={one} className='fixed w-[60%] h-[100%] right-[-200px] top-[-300px] blur-sm rotate-[2deg] ' />
                <img src={one} className='fixed w-[60%] h-[100%] right-[20%] top-[0px] blur-sm ' />

            </div>
        </div>
    )
}
