import React, { useEffect } from 'react';
import Consumer from './Routes/Consumer';
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
} from "react-router-dom";
import Admin from './Routes/Admin';
import './App.css'
import Privacy from './Routes/Privacy';
import TermsOfService from './Routes/TermsOfService';

function App() {


  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Consumer />} />
          <Route path="/admin-checked" element={<Admin />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path='/terms-and-conditions' element={<TermsOfService />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
